import {history} from 'index'
import {PlanningTemplate, PlanningShift} from 'models'
import {usePlanningDates} from 'cores/planning/index'
import SaveForm from './form'
import {useQueryParams} from 'hooks'
import {ModalForm} from 'components/form/index'
import {EitjeModal} from 'components/common'
import {Layout, Message} from 'common/components'
import dateFn from 'initializers/date'
import utils from '@eitje/web_utils'
import {t} from 'initializers/i18n'

const Save = ({filter_active, ...rest}) => {
	return (
		<>
			{filter_active && (
				<Layout borderBottom block>
					<Message t="planning.save_template.filter_active" />
				</Layout>
			)}
			<SaveForm {...rest} />
		</>
	)
}

export const SavePlanningTemplate = props => {
	const {ids, filter_active, date, isWeek, create} = useQueryParams()
	const {year, week, dateRange} = usePlanningDates()
	let kind, weekday
	if (date) {
		kind = 'day'
		weekday = dateFn(date).weekday()
	}
	if (isWeek || ids) kind = 'week'
	const allShifts = PlanningShift.where({date: dateRange})
	const submit = data => {
		if (data.kind == 'day') {
			const shifts = allShifts.filter(s => data.team_ids.includes(s.team_id) && dateFn(s.date).weekday() == data.day_of_week)
			if (shifts.length == 0) return utils.errorNotif({content: t('planning.save_template.no_shifts')})
		}
		return PlanningTemplate.save({
			...data,
			kind: ids ? 'week' : data.kind,
			year,
			week,
			id: data.save_kind ? data.id : null,
			planning_shift_ids: ids,
		})
	}

	return (
		<EitjeModal name="save_template">
			<ModalForm
				afterSubmit={history.goBack}
				onSubmit={submit}
				transNamespace="save_template"
				initialValues={{kind, save_kind: create && 'create', day_of_week: weekday}}
			>
				<Save periodKind={kind} {...props} filter_active={filter_active} />
			</ModalForm>
		</EitjeModal>
	)
}
