import {EitjeButton} from 'common/components'
import {NAMESPACE, useTopicContext} from 'cores/knowledge'
import {createResourceButtons} from '../create_resource_buttons'
import {navigateModal} from 'actions'

export const usePlaceholder = () => {
	const {id, title, resources, trainable, ...topic} = useTopicContext()

	if (!trainable) {
		// if user wants to create a training for a topic, we use always_trainable as default training setting
		const handleCreateTraining = async () => {
			await topic.update({always_trainable: true})
			navigateModal(`/topics/${id}/edit/training`)
		}

		return {
			name: `${NAMESPACE}.create_training`,
			title: {key: `placeholders.${NAMESPACE}.create_training.title`},
			subtitle: {key: `placeholders.${NAMESPACE}.create_training.subtitle`, opts: {title}},
			animation: 'training',
			children: <EitjeButton t="button" iconLeft="gym-barbell" onClick={handleCreateTraining} />,
		}
	}

	if (!resources.length) {
		return {
			name: `${NAMESPACE}.topic_no_trainable_content`,
			animation: 'training',
			children: createResourceButtons,
		}
	}
}
