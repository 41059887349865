import {useQueryParams} from 'hooks'
import {Message} from 'common/components'
import {useOnboardingActive} from 'cores/onboarding'

export const PublishMessage = () => {
	const {filter_active} = useQueryParams()
	const onboardingActive = useOnboardingActive({role: 'manager'})

	if (onboardingActive) return <Message t="planning.publish_shifts.onboarding_message" />

	if (!filter_active) return null

	return <Message t="planning.publish_shifts.filter_active" />
}
